import AdminComplete from "../components/AdminComplete/AdminComplete";

export default function Admin() {
    return (
        <div>
            <div>
                <AdminComplete/>
            </div>
        </div>
    )
}